import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Reporte de facturación
            </h2>
            <p>
                Al iniciar el reporte, comienza por escoger las fechas de inicio y final del reporte. <br /> <b>Posteriormente hay que seleccionar los campos (columnas) de interés para el reporte</b>.
            </p>
            <ImageFooted explanation={`Puedes utilizar esta vista para analizar tu facturación y en su caso, descargarla en CSV para abrirlo en Excel o similar.`}>
                <IMG_Mercadolibre file={`misfacturasReporte`} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/mercadolibre/cancelar-facturas`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;